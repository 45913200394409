<template>
  <v-container>
    <!-- HEADER -->
    <v-row>
      <v-col cols="12" md="" class="mt-3 pa-4 text-left" style="width:100%">
        <v-icon class="mb-4" large>mdi-playlist-check</v-icon>
        <span class="text-h2 ml-2">Verification Lite</span>
      </v-col>
      <v-col cols="12" md="auto" class="text-right">
        <v-alert type="info" style="background-color: var(--v-sidebarColorLight-darken1) !important;margin: 0px; width: auto;">
          For full access to Verify services, email <a href="mailto:briefs@dooh.com" style="font-weight: bold; color: var(--v-primary-base)">briefs@dooh.com</a>
      </v-alert>
      </v-col>
    </v-row>
    <!-- TABS -->
    <template>
      <v-card>
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in computedTabs" :key="item.id">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in computedTabs" :key="item.id">
            <v-card flat>
              <component :is="item.component" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </v-container>
</template>
<script>
import ReportPlayoutVue from './ReportPlayoutVue.vue'

export default {
  data: () => ({
    tab: null,
    tabs: [],
  }),
  components: {
    ReportPlayoutVue
  },
  created() {
    if (!this.$store.state.Permissions.verificationLite)
      this.$router.push({ name: 'Campaigns' })
  },
  mounted() {
    // setup tabs
    this.tabs = [
      {
        name: 'Playout Report',
        id: 1,
        icon: 'mdi-play',
        component: ReportPlayoutVue,
        permission: this.$store.state.Permissions.verificationLite
      },
    ];
  },
  computed: {
    computedTabs() {
      // filter if user has permission
      return this.tabs.filter(tab => tab.permission)
    },
  },
  methods: {
  },
}
</script>
